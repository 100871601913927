import React from 'react'
import { popularWebsites, popularWebsitesSlovensko } from '../../data/popularWebsites'
import CirclesMotionX4 from './CirclesMotionX4'
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import { useBreakpoint } from './contextBreakpointMediaQ';

const clickComp = <StarPurple500Icon sx={{ fontSize: 48 }} />

const optionsLeft = {
    clickComp,
    arrContent: popularWebsites,
    numberOfItems: popularWebsites.length,
    distancePixFromCenter: 150,
    itemHeight: 50,
    itemWidth: 80,
    itemHeightBetween: 10, polyLineXSpace: 10,
}
const optionsRight = {
    clickComp,
    arrContent: popularWebsitesSlovensko,
    numberOfItems: popularWebsitesSlovensko.length, // 9,
    distancePixFromCenter: 150,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10,
}

const optionsTop = {
    clickComp,
    arrContent: popularWebsitesSlovensko.slice(0, 14),
    numberOfItems: 14,
    distancePixFromCenter: 60,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10,
}
const optionsBottom = {
    clickComp,
    arrContent: popularWebsitesSlovensko.slice(14, 27),
    numberOfItems: 13,
    distancePixFromCenter: 60,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10,
}

const CirclesMotionX4PopularWebsitesLR = () => {

    const deviceWidth = useBreakpoint()

    return (<>
        {[ "xl", "lg" ].includes(deviceWidth) && <CirclesMotionX4 name="popularWebsitesAll" arrPosOptions={[
            [ "left", { ...optionsLeft, distancePixFromCenter: 300 } ],
            [ "right", { ...optionsRight, distancePixFromCenter: 300 } ]
        ]} />}
        {[ "md" ].includes(deviceWidth) && <CirclesMotionX4 name="popularWebsitesAll" arrPosOptions={[
            [ "left", { ...optionsLeft, distancePixFromCenter: 180 } ],
            [ "right", { ...optionsRight, distancePixFromCenter: 180 } ]
        ]} />}
        {[ "sm" ].includes(deviceWidth) && (
            <>
                <CirclesMotionX4 name="popularWebsites" arrPosOptions={[
                    [ "left", { ...optionsLeft, distancePixFromCenter: 130 } ],
                    [ "right", { ...optionsRight, distancePixFromCenter: 130 } ]
                ]} key="lr-c" />
            </>
        )}
        {[ "xs" ].includes(deviceWidth) && (
            <>
                <CirclesMotionX4 name="popularWebsites"
                    arrPosOptions={[
                        [ "left", optionsLeft ]
                    ]} key="left-c" />
                <CirclesMotionX4
                    name="popularWebsitesSlov"
                    arrPosOptions={[
                        [ "right", optionsRight ]
                    ]} key="right-c" />
            </>
        )}
    </>)
}

export default CirclesMotionX4PopularWebsitesLR
